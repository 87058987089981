import React from "react";
import { css } from "@emotion/core";
import { mainColor } from "../../constants/colors";
import ReactSlider from "react-slider";
import { Desktop, Tablet } from "../../constants/responsive";

const NumberSlider = ({
  min,
  max,
  step,
  value,
  onChange,
  displayFn,
  label,
}) => {
  return (
    <div
      css={css`
        margin-top: 60px;
        width: 100%;
        height: auto;
        position: relative;
        .slider {
          width: 100%;
          height: 27px;
        }
        ${Desktop} {
          margin-top: 78px;
          .slider {
            width: 100%;
            height: 35px;
          }
        }
      `}
    >
      <ReactSlider
        value={value}
        min={min}
        max={max}
        step={step}
        onChange={onChange}
        renderTrack={(props, state) => {
          return (
            <div
              {...props}
              css={css`
                top: 0;
                bottom: 0;
                background: ${state.index === 1 ? "#d6d6d6" : mainColor};
                border: 10px solid white;
                border-radius: 999px;
                transition: all 0.2s ease;
                ${Desktop} {
                  border: 14px solid white;
                }
              `}
            />
          );
        }}
        renderThumb={(props, state) => {
          return (
            <div
              {...props}
              css={css`
                text-align: center;
                background-color: #000;
                cursor: grab;
                box-shadow: 3px 0 6px 0 rgba(0, 0, 0, 0.24);
                border: solid 3px #ffffff;
                background-color: #ee2f79;
                display: flex;
                justify-content: center;
                align-items: center;
                transition: all 0.2s ease;
                width: 27px;
                height: 27px;
                border-radius: 27px;
                ${Desktop} {
                  width: 35px;
                  height: 35px;
                  border-radius: 35px;
                }
              `}
            >
              <div
                css={css`
                  color: #fff;
                  strong {
                    color: #fff !important;
                  }
                  position: absolute;
                  border-radius: 7px;
                  background: ${mainColor};
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 0px 10px;
                  height: 30px;
                  font-size: 12px;
                  top: -40px;
                  ${Tablet} {
                    height: 35px;
                    font-size: 16px;
                    top: -50px;
                  }
                  ${Desktop} {
                    height: 40px;
                    font-size: 20px;
                    top: -55px;
                  }
                `}
              >
                {displayFn(state.valueNow)}
              </div>
            </div>
          );
        }}
      />
      <div
        css={css`
          div {
            strong {
              color: #000 !important;
            }
          }
          display: flex;
          justify-content: space-between;
          padding: 0px 10px;
          align-items: center;
          line-height: 1;
          margin-top: 10px;
          font-size: 12px;
          ${Tablet} {
            font-size: 16px;
          }
          ${Desktop} {
            margin-top: 18px;
            font-size: 20px;
          }
        `}
      >
        <div>{displayFn(min)}</div>
        <div>{label}</div>
        <div>{displayFn(max)}</div>
      </div>
    </div>
  );
};

export default NumberSlider;
