import { css } from "@emotion/core";
import { useIntl } from "gatsby-plugin-intl";
import React, { useState } from "react";
import { mainColor } from "../../constants/colors";
import { Tablet } from "../../constants/responsive";

const IndividualsDisclaimer = () => {
  const intl = useIntl();
  const [show, setShow] = useState(false);
  return (
    <>
      <div>
        (
        <div
          css={css`
            display: inline-block;
            cursor: pointer;
            transition: all 0.2s ease;
            &:hover {
              color: ${mainColor};
            }
          `}
          onClick={() => {
            setShow(true);
          }}
        >
          {
            { en: "Disclaimer", "zh-cn": "免责声明", "zh-hk": "免責聲明" }[
              intl.locale
            ]
          }
        </div>
        )
      </div>
      {show && (
        <div
          css={css`
            position: fixed;
            z-index: 9999;
            top: 0px;
            left: 0px;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0.6);
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 20px;
          `}
        >
          <div
            css={css`
              border-radius: 20px;
              background: white;
              display: flex;
              flex-direction: column;
              max-width: 600px;
              padding: 30px;
              font-size: 12px;
              line-height: 16px;
              position: relative;
              ${Tablet} {
                font-size: 16px;
                line-height: 24px;
              }
            `}
          >
            <div
              css={css`
                width: 20px;
                height: 20px;
                position: absolute;
                top: 20px;
                right: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
              `}
              onClick={() => {
                setShow(false);
              }}
            >
              <div
                css={css`
                  width: 20px;
                  height: 2px;
                  background: #707070;
                  position: absolute;
                  transform: rotate(45deg);
                `}
              ></div>
              <div
                css={css`
                  width: 20px;
                  height: 2px;
                  background: #707070;
                  position: absolute;
                  transform: rotate(-45deg);
                `}
              ></div>
            </div>
            <div
              css={css`
                text-align: left;
              `}
            >
              <b>
                {
                  {
                    en: "Disclaimer",
                    "zh-cn": "免责声明",
                    "zh-hk": "免責聲明",
                  }[intl.locale]
                }
              </b>
              <br />
              {
                {
                  en: (
                    <div>
                      The calculator data is based on AQUMON SmartGlobal
                      strategy from 2017/01/01 to 2020/08/31, and AQUMON
                      SmartGlobal Max strategy from 2013/01/01 to 2020/08/31.
                      The result are back-tested performance with 0.05% of
                      transaction cost considered. Investment involves risks.
                      Past performance is not indicative of future returns.
                      <br />
                      <br />
                      Any materials, information or reports published,
                      distributed or otherwise made available on the AQUMON
                      website/app is published for information purposes only and
                      is subject to change at any time without notice. They have
                      no regard to the specific investment objectives, financial
                      situation or particular needs of any specific recipient
                      and should not be construed as a solicitation,
                      recommendation or offer, to buy or sell any securities,
                      investment or other financial instruments, to effect any
                      transactions or to conclude any legal act of any kind
                      whatsoever. Unless specifically stated otherwise, all
                      price information is indicative only.
                    </div>
                  ),
                  "zh-cn": (
                    <div>
                      此计算器使用数据来自于AQUMON
                      SmartGlobal组合2017/01/01至2020/08/31和SmartGlobal
                      Max组合2013/01/01至2020/08/31的回测表现。该数据包含0.05%的交易费用。投资有风险，过往收益不代表未来。
                      <br />
                      <br />
                      AQUMON网站/应用程序上发布列明的资讯（包括但不限于文字、图形、数据、模型）只供说明用途，并可随时更改，恕不另行通知。本资讯未考虑任何特定投资者的投资目标、财务状况或特定需求，也不构成招揽、销售、建议或要约任何人使用本资讯所示之投资服务，AQUMON及其发行公司对任何依赖本资讯而引致的损失概不负责。除非另有特别说明，所有价格信息仅供参考。
                    </div>
                  ),
                  "zh-hk": (
                    <div>
                      此計算器使用數據來自於AQUMON
                      SmartGlobal組合2017/01/01至2020/08/31和SmartGlobal
                      Max組合2013/01/01至2020/08/31的回測表現。該數據包含0.05%的交易費用。投資有風險，過往收益不代表未來。
                      <br />
                      <br />
                      AQUMON網站/應用程序上發布列明的資訊（包括但不限於文字、圖形、數據、模型）只供說明用途，並可隨時更改，恕不另行通知。本資訊未考慮任何特定投資者的投資目標、財務狀況或特定需求，也不構成招攬、銷售、建議或要約任何人使用本資訊所示之投資服務，AQUMON及其發行公司對任何依賴本資訊而引致的損失概不負責。除非另有特別說明，所有價格信息僅供參考。
                    </div>
                  ),
                }[intl.locale]
              }
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default IndividualsDisclaimer;
