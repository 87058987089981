import React from "react";
import { css } from "@emotion/core";
import { Desktop, Tablet } from "../../constants/responsive";

const CompositionPercentage = ({ value, label, color }) => {
  return (
    <div
      css={css`
        display: flex;
        width: 140px;
        margin-top: 7px;
        font-weight: 500;
        line-height: 1;
        ${Tablet} {
          width: 200px;
          margin-top: 16px;
        }
        ${Desktop} {
          margin-top: 12px;
        }
      `}
    >
      <div
        css={css`
          flex: 0 0 100px;
          display: flex;
          align-items: center;
          font-size: 12px;
          ${Tablet} {
            font-size: 14px;
            flex: 0 0 140px;
          }
          ${Desktop} {
            font-size: 16px;
          }
        `}
      >
        <div
          css={css`
            width: 14px;
            height: 14px;
            border-radius: 16px;
            background: ${color};
            display: inline-block;
            margin-right: 12px;
            ${Desktop} {
              width: 16px;
              height: 16px;
            }
          `}
        />
        {label}
      </div>
      <div
        css={css`
          flex: 0 0 40px;
          font-size: 12px;
          ${Tablet} {
            font-size: 14px;
            flex: 0 0 60px;
          }
          ${Desktop} {
            font-size: 16px;
          }
        `}
      >{`${(value * 100).toFixed(1)}%`}</div>
    </div>
  );
};

export default CompositionPercentage;
