import React from "react";
import { css } from "@emotion/core";
import { useWindowSize } from "react-use";

const CompositionCirle = ({ percent, color }) => {
  const { width: windowWidth } = useWindowSize();

  let scale = 1;
  if (windowWidth < 1200) {
    scale = 0.5;
  }

  const max = 596 * scale; // 596
  const radius = 110 * scale; // 110
  const width = 30 * scale; // 10

  return (
    <svg
      css={css`
        width: ${radius * 2}px;
        height: ${radius * 2}px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-90deg);
        circle {
          transition: all 0.2s ease;
        }
      `}
    >
      <circle
        cx={radius}
        cy={radius}
        r={radius - width / 2}
        stroke={color}
        strokeWidth={width}
        strokeDasharray={max}
        strokeDashoffset={(1 - percent) * max}
        strokeLinecap="butt"
        fill="none"
      />
    </svg>
  );
};

export default CompositionCirle;
