import React from "react";
import { css } from "@emotion/core";
import Container from "../../components/Container";
import { FormattedHTMLMessage } from "gatsby-plugin-intl";
import ReactSlider from "react-slider";
import { Desktop, Tablet } from "../../constants/responsive";

const RiskSlider = ({ riskData, value, onChange }) => {
  return (
    <Container
      css={css`
        margin-top: 40px;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        ${Desktop} {
          margin-top: 60px;
        }
        ${Desktop} {
          margin-top: 80px;
        }
      `}
    >
      <div
        css={css`
          font-size: 16px;
          ${Desktop} {
            font-size: 22px;
          }
        `}
      >
        <FormattedHTMLMessage id="individuals.portfolio.low" />
      </div>
      <div
        css={css`
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0px 10px;
          .slider {
            width: calc(60vw + 12px);
            min-width: 204px;
            height: 28px;
          }
          ${Tablet} {
            margin: 0px 10px;
            .slider {
              width: 530px;
              height: 48px;
            }
          }
          ${Desktop} {
            margin: 0px 20px;
            .slider {
              width: 680px;
              height: 60px;
            }
          }
        `}
      >
        <div
          css={css`
            position: absolute;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 60vw;
            min-width: 192px;
            ${Tablet} {
              width: 510px;
            }
            ${Desktop} {
              width: 654px;
            }
          `}
        >
          {[1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1, 0, 0, 0, 1].map(
            (big, index) => {
              const size = big ? [16, 28, 34] : [7, 12, 15];
              return (
                <div
                  key={`cicle-${big}-${index}`}
                  css={css`
                    width: ${size[0]}px;
                    height: ${size[0]}px;
                    border-radius: ${size[0]}px;
                    ${Tablet} {
                      width: ${size[1]}px;
                      height: ${size[1]}px;
                      border-radius: ${size[1]}px;
                    }
                    ${Desktop} {
                      width: ${size[2]}px;
                      height: ${size[2]}px;
                      border-radius: ${size[2]}px;
                    }
                    background: #a3a3a3;
                  `}
                />
              );
            }
          )}
        </div>
        <ReactSlider
          value={value}
          step={1}
          min={0}
          max={4}
          onChange={onChange}
          renderThumb={(props, state) => (
            <div
              css={css`
                text-align: center;
                cursor: grab;
                transition: all 0.2s ease;
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                border: solid 3px #ffffff;
                background-color: #ee2f79;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                height: 28px;
                width: 28px;
                border-radius: 28px;
                ${Tablet} {
                  height: 48px;
                  width: 48px;
                  border-radius: 48px;
                }
                ${Desktop} {
                  height: 60px;
                  width: 60px;
                  border-radius: 60px;
                }
              `}
              {...props}
            >
              <div
                css={css`
                  position: absolute;
                  font-size: 20px;
                  font-weight: bold;
                  top: -40px;
                  white-space: nowrap;
                  ${Desktop} {
                    top: -50px;
                    font-size: 25px;
                  }
                `}
              >
                <FormattedHTMLMessage
                  id={`individuals.portfolio.${riskData[state.valueNow].id}`}
                />
              </div>
              {[1, 2, 3].map((i) => {
                return (
                  <div
                    css={css`
                      background: white;
                      border-radius: 3px;
                      width: 14px;
                      height: 1px;
                      margin: 1.5px 0px;
                      ${Tablet} {
                        width: 22px;
                        height: 2px;
                        margin: 3px 0px;
                      }
                      ${Desktop} {
                        width: 35px;
                        height: 3px;
                        margin: 3.5px 0px;
                      }
                    `}
                    key={i}
                  />
                );
              })}
            </div>
          )}
        />
      </div>
      <div
        css={css`
          font-size: 16px;
          ${Desktop} {
            font-size: 22px;
          }
        `}
      >
        <FormattedHTMLMessage id="individuals.portfolio.high" />
      </div>
    </Container>
  );
};

export default RiskSlider;
